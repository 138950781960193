import React from 'react';

import './index.css';

const Service = () => {
    return (
        <div className="section">
            <p>
                A small family service will be held on Saturday, January 23rd at 11:00 AM in Austin, TX.
            </p>
            <p>
                A MAJOR CELEBRATION of Karen's life will be held in the future based on COVID recommendations.
            </p>
            <iframe title="Karen Memorial" className="service-video" style={{marginBottom: "30px"}} src="https://www.youtube.com/embed/FQdwswiuT40" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen />
            <iframe title="Karen Slideshow" className="service-video" src="https://drive.google.com/file/d/1kyoSd5awJ2EEL7JMMO-fEUySy9hYPkJG/preview"
                    allowFullScreen></iframe>
        </div>
    )
};

export default Service;