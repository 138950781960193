import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 768,
            lg: 1100,
            xl: 1400,
        },
    },
    palette: {
        primary: {
            main: 'rgb(130, 55, 148)',
            // dark: '#c20000',
            contrastText: "#fafafa"
        },
        secondary: {
            main: '#4B5491',
            contrastText: '#ffffff',
        },
        // error: will use the default color
    },
    overrides: {
        // MuiCardMedia: {
        //     root: {
        //         backgroundSize: "contain"
        //     }
        // },
        // MuiOutlinedInput: {
        //     notchedOutline: {
        //         borderColor: "#333333"
        //     }
        // },
        // MuiList: {
        //     padding: {
        //         paddingTop: "30px"
        //     }
        // },
        // MuiListItem: {
        //     gutters: {
        //         paddingLeft: 30,
        //         paddingRight: 30
        //     }
        // },
        // MuiAccordion: {
        //     root: {
        //         backgroundColor: "inherit",
        //         boxShadow: "none",
        //         color: "white",
        //         '&$expanded': {
        //            margin: 0
        //         },
        //         '&.MuiAccordion-root:before': {
        //             display: "none"
        //         }
        //     },
        // },
        // MuiAccordionSummary: {
        //     root: {
        //         paddingLeft: 30,
        //         minHeight: 35,
        //         ':before': {
        //             backgroundColor: "none"
        //         },
        //         '&$expanded': {
        //             minHeight: 35
        //         }
        //     },
        //     content: {
        //         margin: 0,
        //         '&$expanded': {
        //             margin: 0
        //         }
        //     }
        // },
        // MuiAccordionDetails: {
        //     root: {
        //         padding: 0
        //     }
        // },
        // MuiAppBar: {
        //     colorDefault: {
        //         backgroundColor: "white"
        //     }
        // },
        // MuiTableRow: {
        //     root: {
        //         "&:last-child td": {
        //             borderBottom: 0,
        //         },
        //     }
        // }
    }
});

export default theme;