import React from 'react';

import karen from '../../img/karen_main.jpg';

import './index.css';

const Home = () => {
    return (
        <div className="home-container section flex">
            <div className="home-main-pic-container">
                <img src={karen} alt="Karen Sparky Strong" className="home-main-pic" />
            </div>
            <div className="home-obit flex1">
                <div>
                    <span className="home-obit-name purple">Karen (Bellinger) Owen</span> was born on June 11, 1949 to loving parents John and Patricia Bellinger of Chicago,
                    Illinois and peacefully passed away, welcomed home to the Lord, on January 15, 2021. She fought a most
                    admirable and courageous fight against Stage 4 Pancreatic cancer, a grim diagnosis she was given three
                    years ago for which she was assigned just three weeks to live. She tackled this challenge head on, with
                    great determination, as she did with everything else in her life. She immediately began researching;
                    reading everything she and her son Steven could get their hands on, seeking out the most experienced
                    doctors, exploring the most up to date options and trials. She even adopted less conventional treatments
                    (dog dewormer, singing bowls, dissonant piano playing, etc.) and left absolutely no stone unturned.
                    And she did all of this under the cloud of the tragic death of her beloved son, Blake, who passed away
                    just four months before her diagnosis. Dealing with that gut wrenching trauma and moving quickly to fight
                    her disease took more courage and physical stamina than most can muster in a lifetime.
                </div>
                <p>
                    Karen’s efforts were rewarded with three challenging yet wonderful years, as she refused to allow
                    the disease to rob her of living her life. She joyfully celebrated her 50th wedding anniversary with
                    the love of her life, Howard; the births of two more grandchildren, Holidays with the family at Camp
                    Owen Colorado and Camp Owen Ozarks, more than 50 birthdays of all her children (5) and grandchildren­­­
                    (12), and hosted a Valentine’s Day tea for 125 of her friends, to name just a few. And she did it
                    with the gut and grace - and humor - that all who knew her to be true.
                </p>
                <p>
                    Between these events, Karen managed to write a 300-page memoir of her 35-month cancer journey,­­­
                    Three Weeks to Live, so others with cancer and their families can learn from her experience. That was
                    Karen; always thinking of others and how she could make a positive difference in their lives. She also
                    maintained a Caring Bridge account throughout her ordeal, updating her friends and family with uplifting
                    and oftentimes humorous accounts of her experience. Aptly signed each time “Karen Sparky Strong”. And
                    Strong was an understatement of who she was.
                </p>
                <p>
                    Karen graduated tenth in her class from Regina Dominican High School in Wilmette, IL. She was the first
                    in her family to graduate college (University of Missouri - Columbia). She continued her education by
                    graduating from Harvard Graduate School of Business’ OPM Program. After cofounding­­­ and serving as
                    the COO of Builder’s Update, she supervised its’ sale to a subsidiary of Kohlberg, Kravis, and Roberts
                    of NYC. At the age of 50, she joined an IBM subsidiary as a National Facilities Manager, eventually
                    rising to the role of IBM’s Director of Education Worldwide Software, traveling to 65 countries on four
                    continents.
                </p>
                <p>
                    Karen was also the President of the Women’s Symphony League of Austin and Austin Dance Club, a member
                    of the Austin Junior Women’s Club, Austin Women’s Club, The Assembly, Art Guild of Laguna Gloria, Austin
                    Natural Science Association Guild, to name just a few. She also had many special friends in her Bible
                    Study Group and Book Club. She was a loyal and devoted friend to all and took great pride in her
                    relationships.
                </p>
                <p>
                    While Karen’s investments of time and energy into business and professional endeavors proved successful
                    and fulfilling, her true feelings of success came from raising her five children. She felt immense­­­
                    pride and celebrated each of their accomplishments, and will be forever missed by Jeff (Lisa, Laney,
                    Riley, and Ford), Steven (Shannon, Westyn, Liam, Brazos and Aspen), Scott (Elizabeth,­­­ Carson, and
                    Amelia) and Tiffany (Kyle, Ethan, and Kalen). Karen is predeceased by her granddaughter­­­ Averie
                    Isabella Owen, and her youngest, cherished son, Blake Andrew Owen.
                </p>
                <p>
                    Not far behind the unconditional devotion to her children and grandchildren was the unwavering love
                    she had for her lifelong partner and husband Howard. He proposed to her only two weeks after meeting
                    her as a Freshman at the University of Illinois and after an all too long 36-month courtship­­­
                    (Karen played her hard-to-get game) they married and moved to Austin in 1972 from Chicago creating a
                    life of community and never looked back. Their marriage was magical; one of passion, respect, encouraging
                    individual successes, and not going to bed angry (Howard did most of the apologizing). Their role
                    modeling set a strong example for their own children to select partners for long, enduring marriages.
                </p>
                <p>
                    Only with the loving, creative, and dedicated care of Joseph Beck, MD, and Debra Tooker, APNR of Highlands
                    Oncology Group of NW Arkansas and the ever insightful guidance of Elyse Rosen LCSW, could Karen have
                    stretched three weeks to three years. Her family is forever grateful for their knowledge, tenacity,
                    and care.
                </p>
                <p>
                    To see her life in photos and join the Zoom service on Saturday January 23 rd at 11:00 AM, click on
                    http://www.karensparkystrong.com
                </p>
                <p>
                    A MAJOR CELEBRATION of Karen’s life will be held in the future based on COVID recommendations.­­­
                </p>
                <p>
                    The family requests that in lieu of flowers, donations be made to:<br/>
                    <span className="purple"><strong>The Grace Grego Maxwell Dell Children’s Mental Health Unit</strong></span><br/>
                    (4900 Mueller Blvd, Austin TX 78723) in the memory of Blake Owen or<br/>
                    <span className="purple"><strong>The Central Texas Food Bank</strong></span> (6500 Metropolis Rd, Austin, TX 78744),<br/>
                    causes very close to Karen’s heart.
                </p>
                <p>
                    It is difficult to put into words Karen’s remarkable life and the many people she impacted along her
                    way. Her legacy will live on far beyond her years on earth. May her memory be an eternal blessing to
                    all who knew and loved Karen.
                </p>
            </div>
        </div>
    )
};

export default Home;