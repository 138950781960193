import firebase from "firebase/app";
import "firebase/firestore";

function initFirebase() {
    let config = {
        apiKey: "AIzaSyA0fVWMq2q5ORL49fAuo1LHVZppQYqbP0Q",
        authDomain: "devs-on-tap.firebaseapp.com",
        databaseURL: "https://devs-on-tap.firebaseio.com",
        projectId: "devs-on-tap",
        storageBucket: "devs-on-tap.appspot.com",
        messagingSenderId: "829146355367",
        appId: "1:829146355367:web:f4171bfa97f6fcf8fa6d82"
    };

    firebase.initializeApp(config);
    // KBM - For testing
    // firebase.functions().useFunctionsEmulator("http://localhost:5001");
}

initFirebase();

const api = {
    getAlbum: () => {
        return firebase.firestore().collection("kowen").doc("album").get();
    },
    getMemories: () => {
        return firebase.firestore().collection("kowen-memories").get();
    },
    saveMemory: memory => {
        return firebase.firestore().collection("kowen-memories").add(memory);
    }
};

export default api;
