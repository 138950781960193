import React, {useState, useEffect} from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {string, object} from 'yup';
import {Formik, Form} from 'formik';

import CustomFormikField from "../_common/formik/customField";
import api from '../../api';

import './index.css';

const Memories = () => {
    const [saving, setSaving] = useState(null);
    const [memories, setMemories] = useState(null);
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        api.getMemories()
            .then(snapshot => {
                setMemories(snapshot.docs.map(doc => doc.data()));
            })
            .catch(err => {
                console.error(err);
                alert('error fetching memories');
            });
    }, []);

    return (
        <div className="section flex column align-items-center">
            <Card className="memories-share-card">
                <Formik
                    initialValues={{
                        message: '',
                        from: ''
                    }}
                    validationSchema={object().shape({
                        message: string().required("Please enter a memory"),
                        from: string().required("Please tell us who this is from")
                    })}
                    onSubmit={(values, {resetForm}) => {
                        setSaving(true);
                        api.saveMemory(values)
                            .then(_ => {
                                setSaving(false);
                                setMemories([values, ...memories]);
                                resetForm();
                            })
                            .catch(err => {
                                alert('error saving memory');
                            })
                    }}>
                    <Form>
                        <CardContent>
                            <div className="memories-share-card-header">
                                Share A Memory of Karen
                            </div>
                            <div className="marginBottom15">
                                <CustomFormikField
                                    CustomComponent={TextField}
                                    name="message"
                                    label="Memory"
                                    placeholder="This one time..."
                                    multiline={true}
                                    fullWidth={true} />
                            </div>
                            <div>
                                <CustomFormikField
                                    CustomComponent={TextField}
                                    name="from"
                                    label="From"
                                    placeholder="Mike & Linda Jones"
                                    fullWidth={true} />
                            </div>
                        </CardContent>
                        <CardActions className="flex memories-share-card-actions">
                            <div className="flex1 marginLeft10">
                                <ReCAPTCHA
                                    sitekey="6LcTyzcaAAAAAP2eosCJgSQXm549cJrYU-Y4baEs"
                                    onChange={() => setVerified(true)}/>
                            </div>
                            <div className="marginRight15 memories-share-card-save">
                                {saving ?
                                    <CircularProgress size={30} /> :
                                    <Button variant="text" disabled={!verified} type="Submit" color="primary">
                                        Save
                                    </Button>
                                }
                            </div>
                        </CardActions>
                    </Form>
                </Formik>
            </Card>
            <div className="memories-list">
                {!memories ?
                    <CircularProgress size={45} /> :
                    <Grid container spacing={3}>
                        {memories.length ?
                            memories.map(({message, from}, index) => (
                                <Grid item key={`memory-${index}`} xs={12} sm={6}>
                                    <div className="memories-memory flex column center">
                                        <div className="memories-memory-inner">
                                            <div className="memories-memory-message">
                                                "{message}"
                                            </div>
                                            <div className="memories-memory-from">
                                                - {from}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            )) :
                            <Grid item xs={12}>
                                <div className="memories-empty purple">
                                    Add your favorite memory above!
                                </div>
                            </Grid>
                        }
                    </Grid>
                }
            </div>
        </div>
    )
};

export default Memories;