import React from 'react';
import {Link} from 'react-router-dom';

import flower from "../../../img/KO_Flower_Bottom.png";

import './index.css';

const Footer = () => {
    const goToTop = () => {
        console.log("scroll to top?!");
        document.body.scrollTop = 0;
    };

    return (
        <div className="footer-container">
            <img src={flower} alt="flower bottom" className="footer-flower-bottom" />
            <div className="footer-links-container flex center">
                <div>
                    <Link to="/service" className="footer-link purple" onClick={goToTop}>
                        The Service
                    </Link>
                </div>
                <div className="footer-divider" />
                <div>
                    <Link to="/gallery" className="footer-link purple" onClick={goToTop}>
                        Gallery
                    </Link>
                </div>
                <div className="footer-divider" />
                <div>
                    <Link to="/memories" className="footer-link purple" onClick={goToTop}>
                        Share A Memory
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default Footer;