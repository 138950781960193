import React, {useState, useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from '../../api';

import './index.css';

const Gallery = () => {
    const [images, setImages] = useState(null);

    useEffect(() => {
        api.getAlbum()
            .then(snapshot => {
                setImages(snapshot.data().photos);
            })
            .catch(err => {
                alert('error fetching photos');
            });
    }, []);

    return (
        <div className="section">
            <div className="gallery-title purple marginBottom30">
                Karen Sparky Strong
            </div>
            <div className="gallery-grid">
                {!images ?
                    <CircularProgress size={45}/> :
                    <Grid spacing={3} container>
                        {images.map((photo, index) => (
                            <Grid key={`row-${index}`} item xs={12} sm={6} lg={4} xl={3} className="flex center">
                                <div className="gallery-image flex center" style={{backgroundImage: `url(${photo.url})`}}
                                    onClick={() => window.open(photo.url)}/>
                            </Grid>
                        ))}
                    </Grid>
                }
            </div>
        </div>
    )
};

export default Gallery;