import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { MuiThemeProvider } from '@material-ui/core/styles';

import TopNav from "./components/nav/top";
import Home from "./components/home";
import Gallery from "./components/gallery";
import Memories from "./components/memories";
import Service from "./components/service";
import Footer from "./components/nav/footer";
import theme from './materialUI/theme';

import '@devsontap/dot-react-common/core/css/atomic.css';
import './App.css';

function App() {
    return (
        <Router>
            <MuiThemeProvider theme={theme}>
                <div className="App scrollY">
                    <TopNav />
                    <Switch>
                        <Route path="/gallery" component={Gallery} />
                        <Route path="/memories" component={Memories} />
                        <Route path="/service" component={Service} />
                        <Route component={Home} />
                    </Switch>
                    <Footer />
                </div>
            </MuiThemeProvider>
        </Router>
    );
}

export default App;
