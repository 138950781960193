import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from "@material-ui/core/IconButton";

import leftFlower from '../../../img/KO_Banner_Flower_Left2.png';
import rightFlower from '../../../img/KO_Banner_Flower_Right2.png';
import kowen from '../../../img/KO_homeButton.png';

import './index.css';

const TopNav = () => {
    const [open, setOpen] = useState(false);

    return (
        <div className="top-nav-container flex" key="top-nav">
            <img src={leftFlower} className="top-nav-left-flower" alt="top left flower" />
            <img src={rightFlower} className="top-nav-right-flower" alt="top right flower" />
            <SwipeableDrawer
                anchor="right"
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}>
                <div className="top-nav-mobile-drawer">
                    <div className="top-nav-link">
                        <Link to="/service" className="top-nav-link" onClick={() => setOpen(false)}>
                            The Service
                        </Link>
                    </div>
                    <div className="top-nav-link">
                        <Link to="/gallery" className="top-nav-link" onClick={() => setOpen(false)}>
                            Gallery
                        </Link>
                    </div>
                    <div className="top-nav-link">
                        <Link to="/memories" className="top-nav-link" onClick={() => setOpen(false)}>
                            Share A Memory
                        </Link>
                    </div>
                </div>
            </SwipeableDrawer>
            <div className="top-nav-logo-container">
                <div>
                    <div>
                        <Link to="/">
                            <img src={kowen} alt="Karen Sparky Strong" className="top-nav-logo" />
                        </Link>
                    </div>
                    <div className="top-nav-logo-years flex justify-end">
                        1949 - 2021
                    </div>
                </div>
            </div>
            <div className="top-nav-links flex align-items-center justify-end flex1">
                <div className="top-nav-link">
                    <Link to="/service" className="top-nav-link">
                        The Service
                    </Link>
                </div>
                <div className="top-nav-link">
                    <Link to="/gallery" className="top-nav-link">
                        Gallery
                    </Link>
                </div>
                <div className="top-nav-link">
                    <Link to="/memories" className="top-nav-link">
                        Share A Memory
                    </Link>
                </div>
            </div>
            <div className="top-nav-mobile-menu">
                <IconButton onClick={() => setOpen(true)}>
                    <MenuIcon htmlColor="#ffffff"/>
                </IconButton>
            </div>
        </div>
    )
};

export default TopNav;